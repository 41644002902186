var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?9df435460bf12775c8efd209735ac5e5";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();

$(document).ready(function () {
  //显示微信二维码
  $(".wx").hover(
    () => {
      $(".weixin-qrcode").show();
    },
    () => {
      $(".weixin-qrcode").hide();
    }
  );
  $(".linkin").hover(
    () => {
      $(".linkin-qrcode").show();
    },
    () => {
      $(".linkin-qrcode").hide();
    }
  );

  //语言下拉菜单
  $(".lang").click((e) => {
    $(".pop_menu").show();
    e.stopPropagation();
  });
  $(document).click((e) => {
    let tar = e.target;
    while (tar) {
      if ($(tar).hasClass("pop_menu")) {
        return;
      }
      tar = tar.parentNode;
    }
    $(".pop_menu").hide();
  });

  // 下拉菜单显示
  $(".menu .menu-item").mouseover(e=>{
    $(".nav-menu .sub-menu").hide();
    const offset = $(e.currentTarget).offset();
    const index = $(e.currentTarget).closest('.menu').find(".menu-item").index(e.currentTarget);
    const subMenu = $(".nav-menu .sub-menu[index="+index+"]");
    subMenu.css("left", offset.left - (subMenu.hasClass("sort") ? -2 : 15) + 'px').show();
  });
  // 下拉菜单隐藏
  $(".sub-menu").mouseleave(e=>{
    $(e.currentTarget).hide();
  });
});